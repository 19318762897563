// used in nps-list.component, shop-list.component

.shops-panel {
    background: var(--sonectBackgroundColor2);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 15px;
    @media screen and (min-width: $sonect-breakpoint-small) {
        padding: 30px;
    }
    
    .shops-panel-btn {
        background: var(--sonectItem);
        border: 2px solid transparent;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 15px;
        width: 100%;
        
        &.selected {
            border: 2px solid $sonect-pink;
        }
        
        &:nth-child(1) {
            margin-bottom: 10px;
        }
        
        &__icon {
            margin-right: 15px;
        }
        
        &__icon--secondary {
            display: flex;
            margin-left: auto;
            transform: rotate(180deg);
            
            svg path {
                stroke: var(--sonectIconColor1);
            }
        }
        
        &__text {
            color: var(--sonectTitleColor);
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    
    .shops-near {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: calc(100% - 154px);
        padding-top: 15px;
        
        &__title {
            color: var(--sonectTitleColor);
            font-weight: bold;
            font-size: 16px;
            flex-shrink: 1;
            margin-bottom: 15px;
        }
        
        .shops-nears-content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            height: calc(100% - 50px);
            overflow-y: auto;
            
            .shops-list {
                list-style: none;
                margin-bottom: 0;
                padding-left: 0;
                
                &__item {
                    background: var(--sonectItem);
                    border: 2px solid transparent;
                    border-radius: 10px;
                    margin-bottom: 15px;
                    
                    &.selected {
                        border: 2px solid $sonect-pink;
                    }
                    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        
        &-actions {
            flex-shrink: 1;
            display: flex;
            justify-content: center;
            padding-top: 20px;
    
            button.sonect-button.main[disabled] {
                background: $sonect-black-2;
            }
        }
    }
    
    app-nps-header {
        margin-bottom: 30px;
    }
}
