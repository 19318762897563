@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "@adyen/adyen-web/dist/adyen.css";
@import "animate.css/animate.min.css";
@import "assets/css/helpers/fonts";
@import "assets/css/helpers/base.default";
@import "assets/css/components/loader";
@import "assets/css/components/modal";
@import "assets/css/components/shop-status";
@import "assets/css/components/shop-list";
@import "assets/css/helpers/variables";
@import "assets/css/helpers/theme";
@import "assets/css/helpers/success-screen";

:host {
    --sonectBackgroundColor1: #000000;
    --sonectTitleColor: #FFFFFF;
    --sonectBackgroundColor2: #202020;
    --sonectBackgroundColor3: #393939;
    --sonectPrimaryColor1: #FF0069;
    --sonectTitleColorActive: #FFFFFF;
    --sonectDescriptionColor: #9B9B9B;
    --sonectMessage: #9B9B9B;
    --sonectBorderColor1: transparent;
    --sonectIconColor1: #FFFFFF;
    --sonectStatusTextColor3: #9B9B9B;
    --sonectBackgroundControl: #9B9B9B;
    --sonectGreyDark: #202020;
    --sonectGreyLight: #393939;
    --sonectCardName: #9B9B9B;
    --sonectItem: #515151;
    --sonectSpotlightTitle: #20202080;
    --sonectHeaderText: #262626;
    --sonectHeaderBackgroundColor: #202020;
    --sonectWithdrawCard: linear-gradient(45deg, #202020 0%, #212121 2.08%, #575757 100%);
}
