@import "src/assets/css/helpers/variables";

.success-screen {
    background-color: var(--sonectBackgroundColor1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 35px 16px 0;
    justify-content: flex-start;
    overflow-y: hidden;
    z-index: 30;
    @media screen and (min-width: $sonect-breakpoint-small) {
        padding-left: 44px;
        padding-right: 44px;
        //padding-bottom: 50px;
    }
    
    .page-header {
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        
        .close {
            path {
                fill: var(--sonectTitleColor);
            }
        }
    }
    
    &-content {
        display: flex;
        flex-direction: column;
        margin-top: 30%;
    }
    
    &__title {
        color: var(--sonectTitleColor);
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        margin-top: 15px;
        text-align: center;
    }
    
    &__description {
        color: $sonect-grey;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        max-width: 250px;
        margin: 15px auto 0;
        text-align: center;
    }
    
    &-action {
        display: flex;
        justify-content: center;
        margin-top: auto;
        padding-top: 15px;
    }
}
