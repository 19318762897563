/*breakpoints*/
$sonect-breakpoint-small: 375px;
/*breakpoints*/

/*colors*/
$sonect-white: #fff;
$sonect-white-2: #FDFDFD;
$sonect-white-3: #F2F2F2;

$sonect-black: #000;
$sonect-black-1: #202020;
$sonect-black-2: #151515;
$sonect-black-3: #393939;
$sonect-black-4: #1E1D1D;
$sonect-black-5: #2C2C2C;
$sonect-black-6: #272727;
$sonect-black-7: #101010;

$sonect-grey: #9B9B9B;
$sonect-grey-2: #575757;
$sonect-grey-3: #535353;
$sonect-grey-4: #909090;
$sonect-grey-5: #494949;

$sonect-green: #BFE500;

$sonect-red: #FF0000;
$sonect-deep-red: #be2827;

$sonect-pink: #FF0069;
$sonect-pink-2: #ff4081;

$sonect-orange: #FFA300;
/*colors*/

/*layout*/
$sonect-padding-top: 30px;
/*layout*/
