@import "src/assets/css/helpers/variables";

.confirm-dialog, .info-dialog-wrapper, .discover-shops-dialog {
    .mat-mdc-dialog-container {
        background-color: var(--sonectBackgroundColor1);
        display: block;
        border-radius: 4px;
        
        .mdc-dialog__surface {
            background-color: transparent;
            box-shadow: none;
            overflow-y: unset;
            padding: 24px;
        }
    }
}

.discover-shops-dialog {
    .mat-mdc-dialog-container {
        background: var(--sonectBackgroundColor2);
    }
}

.cdk-overlay-dark-backdrop {
    background: rgba(70, 69, 69, 0.4);
}
